.card canvas, .card svg {
    margin: 40px auto;
    display: block;
}

.sec-lang {
    align-content: end;
    display: flex;
    flex-flow: row;
    justify-content: end;
}

.btn-lang {
    border: none;
    padding: 5px;
    font-size: 0.75rem;
    margin: 0px;
}
