@import url(https://cdn.jsdelivr.net/npm/normalize.css@8.0.1/normalize.css);
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  /* Personal preferences */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
  background-color: #f0f0f0;
  /* Basics from normalize.css */
  margin: 0;
  -webkit-text-size-adjust: 100%;
  font-size: 1rem;
  font-weight: 400;
  /*
    Normalize.css sets line-height to 1.15 as a standard,
    but I prefer 1.5 as the base for all text,
    because it's a bit more readable and spacious.
  */
  line-height: 1.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
  /* I prefer to put margin at the bottom to scroll past the bottom of the content for readability. */
  margin: 0 auto 4rem;
  /* This width is an arbitrary example. Choose your own value. */
  max-width: 80rem;
}

section {
  /* This value is a personal preference. */
  padding: 1rem;
}

/* This is an arbitrary breakpoint. Choose your own value. */

@media screen and (min-width: 480px) {
  section {
    padding: 2rem;
  }
}

/* Basic text */

/* These values are a personal preference. */

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h1, h2, h3, h4, h5 {
  margin: 0 0 0.5rem 0;
  /* Personally, I prefer lightweight headings. */
  font-weight: 300;
}

p {
  margin: 0 0 0.5rem 0;
  font-weight: 400;
}

/* Links */

a {
  text-decoration: none;
  /* This color is a personal preference. */
  color: #146eeb;
}

a:hover {
  text-decoration: underline;
}

/* Lists */

ul, ol {
  margin: 0.5rem 0 1rem;
  -webkit-padding-start: 2rem;
          padding-inline-start: 2rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin: 0;
}

.list {
  /* Choose your favorite color. */
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin: 0.75rem 0;
  overflow: hidden;
}

.list-item {
  /* Choose how big you want each row to be. */
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.list-item:last-child {
  border-bottom: none;
}

/* Buttons */

/* Base */

.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.15;
  letter-spacing: 0.025rem;
  border: none;
  border-radius: 0.25rem;
  border: 1px solid #aaa;
  background-color: transparent;
  color: #444;
  outline: none;
  text-decoration: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: auto;
  height: auto;
  margin: 0 0.5rem 0.75rem 0;
}

/* Hover and focus */

button:hover, .button:hover, input[type="submit"]:hover, input[type="reset"]:hover, input[type="button"]:hover, button:focus, .button:focus, input[type="submit"]:focus, input[type="reset"]:focus, input[type="button"]:focus {
  text-decoration: none;
  border-color: #555;
  color: #000;
}

/* Extras */

.primary {
  color: #fff;
  border-color: #146eeb;
  background-color: #146eeb;
}

.primary:hover, .primary:focus {
  color: #fff;
  border-color: #125ece;
  background-color: #125ece;
}

.secondary {
  border-color: #ebebeb;
  background-color: #ebebeb;
}

.secondary:hover, .secondary:focus {
  border-color: #e0e0e0;
  background-color: #e0e0e0;
}

.round {
  border-radius: 1.25rem;
  padding: 0.5rem 1rem;
}

/* w/ an icon */

.icon-button {
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  display: flex;
}

.icon-button .icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
}

.card {
  /* This flex basis and max-width of 18rem is arbitrary. */
  flex: 1 0 23rem;
  width: 23rem;
  max-width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 0 5px #e7e7e7;
  /* This margin spacing is also arbitrary. */
  margin: 1rem auto;
}

.card-content {
  padding: 1rem;
  background-color: white;
  border-radius: 0.25rem;
}

.card-media {
  width: 100%;
}

.card-title {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.15;
  margin-top: -1rem;
  padding: 1rem;
  text-transform: uppercase;
  text-align: center;
  border-bottom: solid 1px #ddd;
}

.card-link {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.125rem 0;
  margin: 0 1rem 0 0;
  letter-spacing: 0.025rem;
  display: inline-block;
}

.card-link:hover, .card-link:focus {
  text-decoration: none;
  /* Darker blue */
  color: #125ece;
}

.card-action {
  margin-bottom: 0;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.loader, .spinner {
  /* Space to taste. */
  margin: 1rem;
  /* Resize to taste. */
  width: 4rem;
  height: 4rem;
  /* Color to taste. */
  border: 0.25rem solid #146eeb;
  border-top: 0.25rem solid #eee;
  border-radius: 50%;
  /* Speed the duration to taste. */
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

.loader {
  /* Swap colors: 3/4ths light grey and 1/4th blue */
  border: 0.25rem solid #eee;
  border-top: 0.25rem solid #146eeb;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card div.spinner {
  margin-left: auto;
  margin-right: auto;
}

label {
  display: block;
  font-weight: 500;
  color: #aaa;
  margin: 1.25rem 0;
}

label .right {
  color: #000;
  float: right;
  font-weight: 500;
}

.flex-container {
  display: flex;
  flex-flow: row nowrap;
  /*
    flex-flow is the combination of flex-direction and flex-wrap.
      flex-direction: row;
      flex-wrap: nowrap;
    */
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

.flex-item {
  flex: 0 1 auto;
}

.hidden {
  display: none;
}

.card #message {
  text-align: center;
  margin: 1.5rem 0;
  font-weight: 100;
}

.single-error {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #2a2438;
    color: #eee;
  }
  .card {
    box-shadow: 0 0 5px #24202f;
  }
  .card-content {
    background-color: #352f44;
  }
  .card-title {
    border-color: #a5a0b1;
  }
  label {
    color: #ffffff5e;
  }
  label .right {
    color: #fff;
  }
  .primary {
    border-color: #4dacb1;
    background-color: #09888e;
  }
  .primary:hover, .primary:focus {
    border-color: #4dacb1;
    background-color: #097a80;
  }
}
.card canvas, .card svg {
    margin: 40px auto;
    display: block;
}

.sec-lang {
    align-content: end;
    display: flex;
    flex-flow: row;
    justify-content: end;
}

.btn-lang {
    border: none;
    padding: 5px;
    font-size: 0.75rem;
    margin: 0px;
}

